body {
  background-color: white;
}
.info-user {
  display: flex;
  margin-bottom: 100px;
}
.img {
  display: flex;
  width: 250px;
  height: 250px;
}
.new-img {
  display: block;
  position: relative;
  cursor: pointer;
  margin: 0 15%;
  top: 5%;
  font-weight: bold;
  transition: all ease-out 300ms;
}
.new-img i {
  margin-left: 10%;
  letter-spacing: 5px;
}
.img-user {
  width: 40%;
  height: 250px;
}
.data-user {
  width: 40%;
  height: 250px;
  margin: 20px;
  font-weight: bold;
}

.data-user input {
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
  padding: 5px 5px 5px 10px;

  box-sizing: border-box;
  border: 2px solid;
  border-color: #c4c7c9;
  border-radius: 3px 3px 3px 3px;
}

.data-user button {
  font-size: 100%;
  color: #fff;
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 3px 3px 3px 3px;
  background-color: #6557f5;
  margin-top: 20px;
}
.data-user button:hover {
  background-color: #fff;
  color: #6557f5;
  border: 2px solid;
  border-color: #6557f5;
  cursor: pointer;
}
.info-sec {
  display: flex;
  width: 80%;
  height: 250px;
  margin: 20px;
  font-weight: bold;
}
.info-sec input {
  width: 200px;
  height: 40px;

  padding: 5px 5px 5px 10px;
  margin: 0px 10px 10px 10px;
  box-sizing: border-box;
  border: 2px solid;
  border-color: #c4c7c9;
  border-radius: 3px 3px 3px 3px;
}
.sec-num {
  display: block;
  justify-content: center;
}
.sec-email input {
  margin-left: 55px;
}
.sec-num input {
  margin-left: 30px;
}
.sec-email {
  display: block;
  justify-content: center;
}
.sec-pass {
  display: block;
  justify-content: center;
}
@media screen and (max-width: 440px){
  .info-user {
    display: block;
  }
  .new-img {
    display: block;
    width: 200px;
  }
  .data-user{
    margin-top: 20%;
    width: 80%;
  }
  .info-sec {
    width: 100%;
  }
}
