.fetch {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-weight: bold;
  letter-spacing: 5px;
  margin-bottom: 50px;
  justify-content: space-between;
}

.fetch input {
  width: 300px;
  height: 40px;
  margin-left: 5px;
  padding: 5px 5px 5px 10px;
  box-sizing: border-box;
  border: 2px solid;
  border-color: #c4c7c9;
  border-radius: 3px 3px 3px 3px;
}
.fetch button {
  font-size: 100%;
  color: #fff;
  width: 150px;
  height: 40px;
  border: none;
  border-radius: 3px 3px 3px 3px;
  background-color: #6557f5;
  cursor: pointer;
}

.table {
  width: 100%;
  font-size: 11px;
}

.table th,
td {
  width: 10%;
  text-align: left;
  vertical-align: top;
  border: 1px solid #000;
  border-spacing: 0;
  border-collapse: collapse;
  padding: 0.5em;
  caption-side: bottom;
}
.table caption {
  padding: 0.3em;
  color: white;
  background: black;
}
.table th {
  background: #eee;
}
