.accordion {
  background-color: #9eadba;
  color: black;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 16px;
}
.accordion:after {
  content: "\+";
  font-size: 18px;
  color: black;
  float: right;
  margin-left: 5px;
}
.form-check {
  margin-top: 10%;
  margin-bottom: 10%;
}
.business-advisor {
  display: block;
  width: 100%;
  height: 200px;
  font-weight: bold;
}
.business-advisor select {
  display: flex;
  width: 60%;
  height: 40px;
  margin-top: 10px;
  margin-bottom: 40px;
  padding: 5px 5px 5px 10px;

  box-sizing: border-box;
  border: 2px solid;
  border-color: #c4c7c9;
  border-radius: 3px 3px 3px 3px;
}
.business-advisor input {
  font-size: 100%;
  color: #fff;
  width: 20%;
  height: 40px;
  border: none;
  border-radius: 3px 3px 3px 3px;
  background-color: #1cae9f;
}
.business-advisor button {
  font-size: 100%;
  color: #fff;
  width: 20%;
  height: 40px;
  border: none;
  border-radius: 3px 3px 3px 3px;
  background-color: #6557f5;
  margin-right: 50%;
}
.business-advisor button:hover {
  background-color: #fff;
  color: #6557f5;
  border: 2px solid;
  border-color: #6557f5;
  cursor: pointer;
}
.business-advisor input:hover {
  background-color: #fff;
  color: #1cae9f;
  border: 2px solid;
  border-color: #1cae9f;
  cursor: pointer;
}
.media {
  width: 30%;
  margin-left: 3%;
  margin-bottom: 40px;
}
.media button {
  font-size: 80%;
  color: #fff;
  width: 100%;
  height: 30px;
  border: none;
  border-radius: 3px 3px 3px 3px;
  background-color: #6557f5;
}
.media button:hover {
  background-color: #fff;
  color: #6557f5;
  border: 2px solid;
  border-color: #6557f5;
  cursor: pointer;
}

.image-galery {
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #6557f5;
  color: #fff;
  font-weight: 600;
  border-radius: 5px;
  margin-right: 10px;
}
.image-galery img {
  width: 100% !important;
  height: 100%;
}
.info {
  width: 30%;
  height: 250px;
  margin: 20px;
  margin-left: 3%;
  font-weight: bold;
}
.description {
  width: 100%;
  height: 150px;
}
.name {
  width: 100%;
  height: 40px;
}
.info input {
  margin-bottom: 10px;
  padding: 5px 5px 5px 10px;

  box-sizing: border-box;
  border: 2px solid;
  border-color: #c4c7c9;
  border-radius: 3px 3px 3px 3px;
}

.info button {
  font-size: 100%;
  color: #fff;
  width: 50%;
  height: 40px;
  border: none;
  border-radius: 3px 3px 3px 3px;
  background-color: #1cae9f;
  margin-left: 50%;
}
.info button:hover {
  background-color: #fff;
  color: #1cae9f;
  border: 2px solid;
  border-color: #1cae9f;
  cursor: pointer;
}
.panel-2 {
  display: flex;
}
.list-products {
  width: 30%;
}
.panel-3 {
  display: flex;
  margin-bottom: 20px;
}
.bus-data {
  width: 40%;
  height: 250px;
  font-weight: bold;
  margin-right: 50px;
  margin: 20px;
}
.bus-data input {
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
  padding: 5px 5px 5px 10px;

  box-sizing: border-box;
  border: 2px solid;
  border-color: #c4c7c9;
  border-radius: 3px 3px 3px 3px;
}
.bus-data button {
  font-size: 100%;
  color: #fff;
  width: 50%;
  height: 40px;
  border: none;
  border-radius: 3px 3px 3px 3px;
  background-color: #1cae9f;
}
.bus-data button:hover {
  background-color: #fff;
  color: #1cae9f;
  border: 2px solid;
  border-color: #1cae9f;
  cursor: pointer;
}
.map {
  width: 200px;
  height: 200px;
}
.panel-4 {
  margin-bottom: 20%;
}
.date-recived {
  width: 40%;
  font-weight: bold;
  margin-right: 50px;
  margin: 20px;
}
.date-recived input {
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
  padding: 5px 5px 5px 10px;

  box-sizing: border-box;
  border: 2px solid;
  border-color: #c4c7c9;
  border-radius: 3px 3px 3px 3px;
}
.Customer-info button {
  font-size: 100%;
  color: #fff;
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 3px 3px 3px 3px;
  background-color: #1cae9f;
}
.Customer-info button:hover {
  background-color: #fff;
  color: #1cae9f;
  border: 2px solid;
  border-color: #1cae9f;
  cursor: pointer;
}
.info-recived {
  width: 40%;
  font-weight: bold;
  margin-right: 50px;
}
.info-recived input {
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
  padding: 5px 5px 5px 10px;

  box-sizing: border-box;
  border: 2px solid;
  border-color: #c4c7c9;
  border-radius: 3px 3px 3px 3px;
}
.evidence-delivery {
  width: 100%;
  height: 200px;
}
.wh-url {
  cursor: pointer;
  font-weight: bold;
  transition: all ease-out 300ms;
  margin-left: 60%;
  margin-top: 20px;
}
.wh-url i {
  margin-left: 10%;
  letter-spacing: 5px;
}
.row-galery {
  margin-bottom: 20px;
}
.row-galery button {
  font-size: 100%;
  color: #fff;
  width: 20%;
  height: 40px;
  border: none;
  border-radius: 3px 3px 3px 3px;
  background-color: #1cae9f;
}

.image-galery-botton {
  width: 100%;
  height: 50px;
}
.images-galery {
  width: 100%;
  height: 200px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  background-color: #838b921f;
  overflow-x: scroll;
  overflow-y: hidden;
}

.images-galery::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.images-galery div {
  width: 200px !important;
  height: 200px;
  position: relative;
  background-color: #8383832c;
  margin-right: 5px;
}

.images-galery div img {
  width: 100% !important;
  height: 100% !important;
  cursor: pointer;
}
.buttons-action-images {
  position: absolute;

  background-color: #c4c7c9;
}

.item-image-galery i {
  position: absolute;
  right: 0;
  color: rgb(255, 0, 0);
  background-color: rgb(0, 0, 0);
  border-radius: 3px;
  font-size: 1.5rem;
}
.buttons-form-products {
  width: 100%;
  display: flex;
  justify-content: end;
}
.buttons-form-products button {
  width: 50%;
  margin: 0;
}
.grid-info {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding: 20px 0;
  border-bottom: 1px solid gray;
}
.width100 {
  width: 100% !important;
}
.height100 {
  height: 100%;
}
.display-flex {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 15px;
}
.display-flex input[type="checkbox"] {
  width: 20px;
  height: 20px;
}
.title-box-delivery {
  margin: 25px 0;
}
.galery {
  width: 100%;
  display: flex;
  position: relative;
}
.galery div.prev {
  top: calc(50% - 20px);
  left: 0;
  width: 50px !important;
  height: auto !important;
  position: relative;
  height: 100%;
}
.galery div.next {
  position: relative;
  top: calc(50% - 20px) !important;
  right: 0;
  width: 50px !important;
  height: auto !important;
  height: 100%;
}
.galery div button {
  background-color: transparent !important;
  color: #6557f5;
  height: 100%;
  border: none;
}
.mt-25 {
  margin-top: 25px;
}
.box-title {
  display: flex;
  align-items: center;
}
.indicator-active {
  background-color: #0d6efd;
  color: #fff;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -60px;
  margin-right: 10px;
  width: 50px;
  height: 50px;
  border-radius: 50px;
}
.indicator-disable {
  background-color: #fff;
  color: #0d6efd;
  font-weight: 700;
  border: 2px solid #0d6efd;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -60px;
  margin-right: 10px;
  width: 50px;
  height: 50px;
  border-radius: 50px;
}
.btn-continuar {
  width: 100%;
  display: flex;
  justify-content: end;
}
.btn-continuar .btn {
  width: 50% !important;
}
.height200px {
  height: 200px;
}
.btn-primary {
  background-color: #0d6efd !important;
  border: none;
}
.galery input[type="file"] {
  padding: 0;
}
.galery input[type="file"]::before {
  width: 100%;
  height: 100%;
  content: "Añadir imagen";
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  font-size: 1rem;
}
/*
  * CSS - PopUp Images
  */
.file-container {
  width: 100%;
  height: 40%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 50px;
  border-radius: 3px;
  padding: 10px;
  cursor: pointer;
}

.file-container input {
  width: 100px;
  height: 100px;
  position: relative;
  border-radius: 10px;
}

.file-container input::before {
  content:""; 
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
  width: 100px !important;
  height: 100px !important;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  z-index: 9999;
}

.file-container input:nth-child(1)::before{
  background-image: url("./../../../resources/logo_camera.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.file-container input:nth-child(2)::before{
  background-image: url("./../../../resources/logo_galeria.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.input-zone{
  width: 100%;
  height: 40px;
  border-radius: 5px;
}

@media screen and (max-width: 440px) {
  .galery input[type="file"] {
    height: 60px;
    overflow: hidden;
  }
  .galery input[type="file"]::before {
    width: 100% !important;
    height: 60px !important;
    content: "Añadir imagen";
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    font-size: 1rem;
  }
  .business-advisor {
    margin-bottom: 20px;
    align-items: center;
  }
  .business-advisor select {
    width: 100%;
  }
  .business-advisor button {
    font-size: 100%;
    color: #fff;
    width: 100%;
    border: none;
    border-radius: 3px 3px 3px 3px;
    background-color: #6557f5;
    margin-bottom: 10px;
  }
  .business-advisor input {
    font-size: 100%;
    color: #fff;
    width: 100%;
    height: 40px;
    border: none;
    border-radius: 3px 3px 3px 3px;
    background-color: #1cae9f;
    margin-bottom: 20px;
  }
  .list-products {
    width: 100%;
  }
  .panel-2 {
    display: block;
    margin-bottom: 20px;
  }
  .media {
    width: 90%;
  }
  .image-galery {
    width: 50%;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-left: 20%;
  }
  .info {
    width: 90%;
    height: 250px;
    margin: 20px;
    margin-left: 3%;
    font-weight: bold;
    margin-bottom: 20%;
  }
  .bus-data {
    width: 100%;
  }
  .bus-data input {
    width: 320px;
  }

  .bus-data button {
    width: 100%;
    margin-top: 10%;
  }
  .galery {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 15px;
  }
  .grid-info {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    padding: 20px 0;
    border-bottom: 1px solid gray;
  }
  .images-galery {
    margin-top: 15px;
  }
  .prev,
  .next {
    display: none;
  }
}
