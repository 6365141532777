.fetch {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    font-weight: bold;
    letter-spacing: 5px;
    margin-bottom: 50px;
    justify-content: space-between;
  }
  .table-mobile{
    display: none;
  }
  .fetch input {
    width: 300px;
    height: 40px;
    margin-left: 5px;
    padding: 5px 5px 5px 10px;
    box-sizing: border-box;
    border: 2px solid;
    border-color: #c4c7c9;
    border-radius: 3px 3px 3px 3px;
  }
  .fetch button {
    font-size: 100%;
    color: #fff;
    width: 150px;
    height: 40px;
    border: none;
    border-radius: 3px 3px 3px 3px;
    background-color: #6557f5;
    cursor: pointer;
  }
  
  .table {
    width: 100%;
  }
  
  .table th,
  td {
    width: 10%;
    text-align: left;
    vertical-align: top;
    border: 1px solid #000;
    border-spacing: 0;
    border-collapse: collapse;
    padding: 0.5em;
    caption-side: bottom;
  }
  .table caption {
    padding: 0.3em;
    color: white;
    background: black;
  }
  .table th {
    background: #eee;
  }
  @media screen and (max-width: 440px){
    .fetch {
      display: block;
      margin-left: -10%;
      flex-direction: column;
      margin-right: 0%;
    }
    .fetch button {
      margin-top: 20px;
    }
    thead {
      display: none;
    }
    tr:nth-of-type(2n){
      background-color: inherit;
    }
    tbody td{
      display: block;
      text-align: center;
      width: 80%;
      border: none;
    }
    tbody tr{
      border: 1px solid black !important;
    }
   
    tbody td:before{
      content: attr(data-th);
      display: block;
    }
    td:nth-of-type(1):before { content: "Id"; }
    td:nth-of-type(2):before { content: "Perfil"; }
    td:nth-of-type(3):before { content: "Nombres"; }
    td:nth-of-type(4):before { content: "Apellidos"; }
    td:nth-of-type(5):before { content: "Correo"; }
    td:nth-of-type(6):before { content: "Teléfono"; }
  }