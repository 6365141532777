.pincipal-lateral {
  width: 20%;
}
nav {
  position: relative;
  background: #f7f7f7;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
nav ul {
  list-style: none;
}
nav li {
  position: relative;
  margin: 1em 0;
  cursor: pointer;
  padding-left: 10px;
}
nav li:hover {
  color: red !important;
}
nav li i {
  margin-right: 5px;
}
nav a {
  line-height: 5em;
  text-transform: uppercase;
  text-decoration: none;
  color: black;
  display: block;
  transition: all ease-in-out 100ms;
  letter-spacing: 2px;
}
a:active {
  color: white;
}
:not(.active)::after {
  opacity: 0.2;
}
:not(.active):hover a {
  color: rgba(#fff, 0.75);
}
#salida {
  position: relative;
  margin: 3em;
  cursor: pointer;
  display: block;
}
#salida:hover {
  color: red;
}
.display-none-desktop {
  display: none;
}
.logo {
  margin-right: 20px;
}

.display-none-mobile .select {
  background-color: #fff !important;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}
nav.bar-user {
  width: 100%;
  height: auto !important;
  display: flex;
  position: fixed;
  top: 0;
  z-index: 999;
  height: 60px !important;
}
.bar-user ul {
  width: 100%;
  display: flex;
  justify-content: space-around;
  text-transform: uppercase;
}
.logo-user {
  width: 100px !important;
}
.bar-user {
  width: 100%;
  text-transform: uppercase;
}
.logo-user img {
  width: 100% !important;
}
@media screen and (max-width: 440px) {
  nav {
    display: none;
  }
  .pincipal-lateral {
    width: 100%;
  }
  .display-none-mobile {
    display: none;
  }
  .display-none-desktop div {
    display: flex;
    margin: 15px;
    width: 100%;
    justify-content: center;
  }
  .display-none-desktop {
    background: #f7f7f7;
    display: flex;
    flex-direction: column;
  }
  .item-mobile {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .item-mobile a {
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    height: 40px;
    color: black;
  }
  button.display-none-desktop {
    height: 40px;
    width: 40px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .find {
    margin-top: 30px;
    display: flex;
    align-items: center;
    width: 90%;
  }
  #exit {
    margin: 10px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  h5 {
    font-size: 11px !important;
  }
  .logo-user {
    width: 100px !important;
  }
  .bar-user {
    width: 100%;
    text-transform: uppercase;
  }
  .logo-user img {
    width: 100% !important;
  }
  nav.bar-user {
    width: 100%;
    height: auto !important;
    display: flex;
    position: fixed;
    top: 0;
    z-index: 999;
    height: 60px !important;
  }
}
