.filter {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    font-weight: bold;
    letter-spacing: 5px;
    margin-bottom: 50px;
    justify-content: space-between;
  }
  .filter input {
    width: 300px;
    height: 40px;
    margin-left: 5px;
    padding: 5px 5px 5px 10px;
    box-sizing: border-box;
    border: 2px solid;
    border-color: #c4c7c9;
    border-radius: 3px 3px 3px 3px;
  }
  .filter button {
    font-size: 100%;
    color: #fff;
    width: 150px;
    height: 40px;
    border: none;
    border-radius: 3px 3px 3px 3px;
    background-color: #6557f5;
    cursor: pointer;
  }
  .button-actions{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .button-actions{
    cursor: pointer;
  }
  .filter-box{
    display: flex;
    width: 100%;
    align-items: center;
    position: relative;
    align-items: center !important;
  }
  .remove-all-filter{
    position: relative;
    left: -25px;
  }
  .paginate{
    display: flex;
    justify-content: center;
    list-style: none;
    width: 100%;
    background-color: #0d6efd;
    color: white;
    font-weight: 700;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 3px;
  }
  .paginate a{
    color: #fff;
    text-decoration: none;
  }
  .paginate-active{
    border-radius: 50px;
    background-color: #fff;
    color: #0d6efd !important;
  }
  .mrg-right{
    margin-right: 15px;
  }
  @media screen and (max-width: 440px){
    .filter {
      display: block;
      flex-direction: column;
      margin-right: 0%;
    }
    .filter button {
      width: 100% !important;
      margin-top: 20px;
    }
    .table-responsive{
      width: 100%;
      justify-content: center;
      align-items: center;
    }
    .table-responsive td{
     width: 100%;
    }

    .table{
      width: 100% !important; 
      
    }
  }
  