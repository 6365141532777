.dashboard{
    display: flex;
    height: 100vh;
    overflow: hidden;
}
.content-dashboard{
    width: 80%;
    padding: 30px;
    height: 100vh;
    overflow-y: scroll;
    
}
.grid-dashboard{
    margin-top: 25px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
}

.grid-dashboard a{
    border-radius: 25px;
    height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 2.5em;
    color: black;
    text-decoration: none;
    text-align: center;
    box-shadow: 1px 3px 5px 2px rgba(0, 0, 0, 0.2);
}

.grid-dashboard a:hover{
    background-color: brown;
    color: #fff;
}
.dashboard-advisor{
    display: block;
    width: 80%;
    margin: auto;
    padding: 25px;
    background-color: rgba(188, 192, 191, 0.123) !important;
    margin-top: 60px;
    border-left: 4px dotted #0d6efd;
}


@media screen and (max-width: 440px) {
    .dashboard{
        height: 100vh;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }
    .content-dashboard{
        width: 100%;
        padding: 30px;
        height: 100vh;
        overflow-y: scroll;
        
    }
    .grid-dashboard {
        display: flex;
        flex-direction: column;
        font-size: 0.8em;
    }
}