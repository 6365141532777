.buttom-to{
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 3px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.filter {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-weight: bold;
  letter-spacing: 5px;
  margin-bottom: 50px;
  justify-content: space-between;
}
.filter input {
  width: 300px;
  height: 40px;
  margin-left: 5px;
  padding: 5px 5px 5px 10px;
  box-sizing: border-box;
  border: 2px solid;
  border-color: #c4c7c9;
  border-radius: 3px 3px 3px 3px;
}
.filter button {
  font-size: 100%;
  color: #fff;
  width: 150px;
  height: 40px;
  border: none;
  border-radius: 3px 3px 3px 3px;
  background-color: #6557f5;
  cursor: pointer;
}
